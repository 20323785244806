/**
 * Compare two object in deep
 * @param {Object} x
 * @param {Object} y
 * @returns {false|this is string[]|boolean}
 */
export function deepEqual  (x, y) {
    const ok = Object.keys,
        tx = typeof x,
        ty = typeof y
    return x && y && tx === "object" && tx === ty
        ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
        : x === y
}